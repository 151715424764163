<template>
  <div class="error">
    <h1>404</h1>
    <p>Sorry, this page can't be found.</p>
  </div>
</template>

<script>
export default {
  name: '404-not-found',
  components: {},
  props: {},
  data() {
    return {};
  },
  created() {},
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.error {
  padding: 30px;
  margin-top: 2em;
}
</style>
